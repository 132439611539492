import React from 'react';

import { useSafeRouter } from '@/hooks';

import styles from '@/components/Layouts/GNBLayout/GNBLayout.module.scss';
import colors from '@/styles/constants/_colors.module.scss';

const PageNoutFound = () => {
  const { safePush } = useSafeRouter();
  const handleClick = () => {
    safePush('/');
  };

  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: colors.BG1,
        width: '100vw',
        height: '100vh',
      }}
    >
      <div
        style={{
          position: 'absolute',
          fontWeight: '700',
          zIndex: 10,
          color: colors.n80,
          textAlign: 'center',
          marginTop: 200,
          marginLeft: 0,
          marginRight: 0,
          left: 0,
          right: 0,
          fontSize: '80px',
        }}
      >
        <p>404</p>
        <p>Page Not Found</p>
        <button
          style={{
            marginTop: '100px',
            width: '400px',
            height: '100px',
            fontSize: '40px',
          }}
          onClick={handleClick}
          className={styles.button}
        >
          홈으로
        </button>
      </div>
    </div>
  );
};

export default PageNoutFound;
